const header = {
	headerElem: document.querySelector('#header'),
	content: document.querySelector('#content'),
	headerOpen: document.querySelector('#header_menu_open'),
	headerClose: document.querySelector('#header_menu_close'),
	toggleSticky() {
		if (window.scrollY > 0) {
			this.headerElem.classList.add('sticky');
			this.content.style.paddingTop = this.headerElem.offsetHeight + "px";
		} else {
			this.headerElem.classList.remove('sticky');
			this.content.style.paddingTop = 0;
		}
	},
	toggleMenu() {
		document.getElementById('header_menu').classList.toggle('open');
	},
	toggleSubMenus() {
		document.querySelectorAll('.menu_main_link').forEach(link => {
			link.addEventListener('mouseover', e => {
				document.querySelectorAll('.menu_main_link').forEach(link => {
					if (link !== e.target) {
						link.classList.remove('active');
					}
				});
				e.target.classList.add('active');

				document.querySelectorAll('.header_menu_sub_menu').forEach(menu => {
					menu.classList.remove('active');
				});
				let sub = e.target.parentElement.querySelector('.header_menu_sub_menu');
				if (sub) {
					sub.classList.add('active');
				}
			});

			if (!link.classList.contains('no_sub')) {
				link.addEventListener('touchend', e => {
					document.querySelectorAll('.menu_main_link').forEach(link => {
						if (link !== e.target) {
							link.dataset.clicks = 0;
							link.classList.remove('active');
						}
					});
					if (!link.dataset.clicks || parseInt(link.dataset.clicks) === 0) {
						e.preventDefault();
					}
					e.target.dataset.clicks = 1;
					e.target.classList.add('active');

					document.querySelectorAll('.header_menu_sub_menu').forEach(menu => {
						menu.classList.remove('active');
					});
					e.target.parentElement.querySelector('.header_menu_sub_menu').classList.add('active');
				});
			}
		});
	},
	init() {
		window.addEventListener('scroll', this.toggleSticky.bind(this));
		this.headerOpen.addEventListener('click', this.toggleMenu.bind(this));
		this.headerClose.addEventListener('click', this.toggleMenu.bind(this));
		this.toggleSubMenus();
	}
};

export const initHeader = () => header.init();