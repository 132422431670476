const careers = {
	submitInProgress: false,
	required: ['first_name', 'last_name', 'address', 'city', 'state', 'zip', 'country', 'phone', 'email'],

	updateError(errors) {
		var response = '';
		console.log(errors);
		if (errors.length === 1) {
			response = "The " + errors[0] + " field is required.";
		} else if (errors.length === 2) {
			response = "The " + errors[0] + " and " + errors[1] + " fields are required.";
		} else {
			let fields = "";
			for (let x = 0; x < errors.length; x++) {
				fields += errors[x];
				if (x < errors.length - 1) {
					fields += ', ';
				}
				if (x === errors.length - 2) {
					fields += 'and ';
				}
			}
			response = "The " + fields + " fields are required.";
		}
		response = response.replaceAll('_', ' ');
		document.getElementById('careers_response').innerHTML = response;
	},

	init() {
		const container = document.getElementById('careers_page');
		if (container) {
			document.querySelector('#career_submit').addEventListener('click', e => {
				e.preventDefault();
				e.stopImmediatePropagation();

				document.querySelectorAll('.error').forEach(e => {
					e.classList.remove('error');
				});

				document.getElementById('careers_response').innerHTML = "";

				if (this.submitInProgress) {
					return;
				}

				if (!(document.getElementById('full_time').checked || document.getElementById('part_time').checked || document.getElementById('intern').checked)) {
					document.getElementById('careers_response').innerHTML = "Please select an interest level.";
					return;
				}

				let errorsFound = new Array();
				this.required.forEach(input => {
					if (document.getElementById(input + '_input').value === '') {
						document.getElementById(input + '_input').classList.add('error');
						errorsFound.push(input);
					}
				});

				if (!this.submitInProgress && errorsFound.length === 0) {
					this.submitInProgress = true;

					let form = document.getElementById('careers_form');

					let request = new XMLHttpRequest(),
						method = 'POST',
						url = form.getAttribute('action'),
						data = new FormData(form);

					request.onreadystatechange = () => {
						if (request.readyState === 4 && request.status === 200) {
							let response = JSON.parse(request.responseText);
							if (response.ret_det.success) {
								window.location.href = "/thank-you?page=c";
							} else {
								if (typeof response.data.error_fields !== 'undefined') {
									let errors = [];
									for (let x = 0; x < response.data.error_fields.length; x++) {
										errors.push(response.data.error_fields[x].field_name);
									}
									this.updateError(errors);
								} else {
									document.getElementById('careers_response').innerHTML = response.ret_det.message;
								}
							}
							this.submitInProgress = false;
						}
					};

					request.open(method, url, true);
					request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
					request.send(data);
				} else if (errorsFound.length > 0) {
					this.updateError(errorsFound);
				} else {
					document.getElementById('careers_response').innerHTML = "<span class='error'>There was a problem processing your request.</span>";
				}
			});
		}
	}
};

export const initCareers = () => careers.init();