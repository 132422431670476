const transitionIn = {
	isElementInViewport(el) {
		const rect = el.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	},
	checkVisibility() {
		document.querySelectorAll('.on_scroll').forEach(element => {
			if (this.isElementInViewport(element)) {
				element.classList.add('on_scroll_visible');
			}
		});
	},
	init() {
		const boundCheckVisibility = this.checkVisibility.bind(this);
		window.addEventListener('scroll', boundCheckVisibility);
		window.addEventListener('resize', boundCheckVisibility);
		this.checkVisibility();
	}
};

const accordians = {
	recordHeights() {
		document.querySelectorAll('.accordian_bottom').forEach(bottom => {
			bottom.previousElementSibling.classList.remove('open');
			bottom.style.height = "auto";
			bottom.dataset.h = bottom.offsetHeight;
			bottom.dataset.open = "0";
			bottom.style.height = 0;
		});
	},
	addEventListeners() {
		document.querySelectorAll('.accordian_top').forEach(top => {
			top.addEventListener('click', e => {
				let target = e.target;
				while (!target.classList.contains('accordian_top')) {
					target = target.parentElement;
				}
				target.classList.toggle('open');
				target = target.parentElement;
				let content = target.nextElementSibling;
				if (content.dataset.open === "0") {
					let height = content.dataset.h;
					content.style.height = height + "px";
					content.dataset.open = "1";
				} else {
					content.style.height = 0;
					content.dataset.open = "0";
				}
			});
		});
	},
	init() {
		this.recordHeights();
		this.addEventListeners();
		window.addEventListener('resize', this.recordHeights);
	}
};

const genderSelect = {
	recordHeights() {
		document.querySelectorAll('.gender_content').forEach(genderSection => {
			genderSection.classList.add('open');
			genderSection.dataset.height = genderSection.offsetHeight;
			genderSection.style.height = 0;
			genderSection.classList.remove('open');
		});
	},
	openSection(gender) {
		document.querySelectorAll('.gender_content').forEach(gc => {
			gc.style.height = 0;
			gc.classList.remove('open');
			gc.classList.remove('visible');
		});
		let section = document.querySelector(`#${gender}_content`);
		section.classList.add('open');
		setTimeout(() => {
			section.style.height = section.dataset.height + 'px';
		}, 100);
		setTimeout(() => {
			section.classList.add('visible');
		}, 100);
	},
	addEventListeners() {
		document.querySelectorAll('.gender_select').forEach(b => {
			b.addEventListener('click', e => {
				document.querySelectorAll('.gender_select').forEach(b => {
					b.classList.remove('open');
					b.classList.add('hide');
				});
				let el = e.target;
				while (!el.classList.contains('gender_select')) {
					el = el.parentElement;
				}
				el.classList.remove('hide');
				el.classList.toggle('open');
				this.openSection(el.dataset.gender);
			});
		});

	},
	init() {
		setTimeout(() => {
			this.recordHeights();
			this.addEventListeners();
		}, 100);
	}
};

export const initTransitionIn = () => transitionIn.init();
export const intAccordians = () => accordians.init();
export const initGenderSelect = () => genderSelect.init();